import {createTheme} from '@mui/material/styles';

export const colors = {
  primary: '#400505',
  primary2: '#820505',
  primary3: '#450303',
  secondary: '#243665',
  dark: '#222222',
  lightDark: '#777777',
  extra: '#3B48C3',
  success: '#4CAF50',
  success2: '#D4ECD5',
  success3: '#3DC627',
  error: '#F44336',
  light: '#FCFCFC',
  light2: '#E2E2E3',
  light3: '#9A9FA5',
  light4: '#EFEFEF',
  dark1: '#C00707',
  dark2: '#0C1B2A',
  dark3: '#1A0909',
  dark4: '#E63D3D',
  ongoing: '#C39A13',
  light5: '#F1F1F1',
  light6: '#f8f8f8',
  light7: '#9b9b9b',
  light8: '#FFFFFE',
  light9: '#E0E3EB',
  light10: '#F7EBEB',
  light11: '#5B3434',
};

export const pointer = {
  cursor: 'pointer',
};

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
  },
  typography: {
    h1: {
      fontSize: '60px !important',
      lineHeight: '73px !important',
      fontFamily: 'Montserrat !important',
      fontWeight: '700 !important',
    },
    h2: {
      fontSize: '30px !important',
      fontFamily: 'Montserrat !important ',
      fontWeight: '700 !important',
    },

    h3: {
      fontSize: '24px !important',
      fontFamily: 'Montserrat !important',
      fontWeight: '700 !important',
    },
    h4: {
      fontSize: '20px',
      lineHeight: '149%',
      fontFamily: 'Montserrat',
      fontWeight: '700',
    },

    h5: {
      fontSize: '16px !important',
      lineHeight: '149% !important',
      fontFamily: 'Montserrat !important',
      fontWeight: '700 !important',
    },
    h6: {
      fontSize: '14px ',
      lineHeight: '21px !important',
      fontFamily: 'Montserrat !important',
      fontWeight: '400 !important',
    },
    subtitle1: {
      fontSize: '13px !important',
      lineHeight: '140% !important',
      fontFamily: 'Montserrat !important',
      fontWeight: '700 !important',
    },
  },
});

export default theme;
