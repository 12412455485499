import openStixIcon from 'assets/images/openStixDesktopIcon.svg';
import selectedOpenStix from 'assets/images/menu/selectedOpenStix.svg';
import ReportsIcon from 'assets/images/desktopReportsIcon.svg';
import selectedReports from 'assets/images/menu/selectedReports.svg';
import FeedbackIcon from 'assets/images/desktopFeedbackIcon.svg';
import selectedFeedback from 'assets/images/menu/seletedFeedback.svg';
import MojoIcon from 'assets/images/DesktopMojoIcon.svg';
import selectedMojo from 'assets/images/menu/selectedMojo.svg';
import desktopManageIcon from 'assets/images/desktopManageIcon.svg';
import selectedManage from 'assets/images/menu/selectedManage.svg';
import settings from 'assets/images/settings.svg';
import selectedSetting from 'assets/images/menu/selectedSettings.svg';

export const commonStyleProps = {
  activeBoxShadow: '0px 0px 0px 2px #DBF2FB, 0px 0px 0px 1px #77CBED inset',
  borderColor: '#9A9FA5',
  hoverBoxShadow: '0px 0px 0px 2px #dae3f0, 0px 0px 0px 1px #9A9FA5 inset',
  labelSize: 16,
  fontFamily: 'Montserrat',
};

export const MenuItems = [
  {
    id: 1,
    label: 'Open Stix',
    icon: openStixIcon,
    selectedIcon: selectedOpenStix,
    route: '/open-stix',
    basePath: 'open-stix',
    isMainMenu: true,
  },
  {
    id: 2,
    label: 'Reports',
    icon: ReportsIcon,
    selectedIcon: selectedReports,
    route: '/reports',
    basePath: 'reports',
    isMainMenu: true,
  },
  {
    id: 3,
    label: 'Manage',
    icon: desktopManageIcon,
    selectedIcon: selectedManage,
    route: '/manage-yardstix',
    basePath: 'manage-yardstix',
    isMainMenu: true,
  },
  {
    id: 4,
    label: 'Mojo',
    icon: MojoIcon,
    selectedIcon: selectedMojo,
    route: '/create-mojo',
    basePath: 'create-mojo',
    isMainMenu: true,
  },
  {
    id: 5,
    label: 'Feedback',
    icon: FeedbackIcon,
    selectedIcon: selectedFeedback,
    route: '/feedback',
    basePath: 'feedback',
    isMainMenu: true,
  },
  {
    id: 6,
    label: 'Settings',
    icon: settings,
    selectedIcon: selectedSetting,
    route: '/account-settings',
    basePath: 'account-settings',
    isMainMenu: true,
  },
  {
    label: 'Open Stix',
    route: '/user-home',
    basePath: 'user-home',
    isMainMenu: false,
  },
  {
    label: 'Open Stix',
    route: '/questions',
    basePath: 'questions',
    isMainMenu: false,
  },
  {
    label: 'Reports',
    route: '/overallprogressDetails/idSurvey',
    basePath: 'overallprogressDetails',
    isMainMenu: false,
  },
  {
    label: 'Mojo',
    route: '/mojo-created',
    basePath: 'mojo-created',
    isMainMenu: false,
  },
  {
    label: 'Reports',
    route: '/mojoReport/mojoId',
    basePath: 'mojoReport',
    isMainMenu: false,
  },
  {
    label: 'Manage',
    route: '/add-survey',
    basePath: 'add-survey',
    isMainMenu: false,
  },
  {
    label: 'Manage',
    route: '/create-workspace',
    basePath: 'create-workspace',
    isMainMenu: false,
  },
  {
    label: 'Manage',
    route: '/workspace-created',
    basePath: 'workspace-created',
    isMainMenu: false,
  },
  {
    label: 'Manage',
    route: '/add-edit-user',
    basePath: 'add-edit-user',
    isMainMenu: false,
  },
  {
    label: 'Manage',
    route: '/workspace-users',
    basePath: 'workspace-users',
    isMainMenu: false,
  },
  {
    label: 'Manage',
    route: '/add-edit-groups',
    basePath: 'add-edit-groups',
    isMainMenu: false,
  },

  {
    label: 'Manage',
    route: '/edit-workspace',
    basePath: 'edit-workspace',
    isMainMenu: false,
  },
  {
    label: 'Mojo',
    route: '/add-participants',
    basePath: 'add-participants',
    isMainMenu: false,
  },
  {
    label: 'Mojo',
    route: '/mojo-created1',
    basePath: 'mojo-created1',
    isMainMenu: false,
  },
];

export const valid_guid_message = 'Valid Guild-Id';
