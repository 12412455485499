import React, {useEffect, useState} from 'react';
import {useNavigate, useParams, useLocation} from 'react-router';
import {Grid, Typography, Box, Dialog, Popper} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {styled} from '@mui/system';
import {NimbleButton} from 'nimble-design-system';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import {useApiResponseHandler} from 'app/utils/CustomHooks';
import {colors} from 'theme';
import {BottomNavigation} from 'app/shared/components';
import {actions as ReportActions} from 'app/store/slices/report.slice';
import useCheckIsMobile from 'app/utils/useCheckIsMobile';
import Pagelayout from 'app/Layout/PageLayout';
import InfoPopper from 'app/modules/report/containers/infoPopper/infoPopper.component';
import {CommentMojo, MatrixMojoDetailReport, MojoDetailReport} from '../../components';
import base64ToExcelFile from 'app/utils/DownloadExcelFile';
import {changeFormatOfDate} from 'app/utils/validPassword';
import ShareModal from 'app/shared/components/shareModal/shareModal';
import AnonymousLayout from 'app/Layout/AnonymousLayout';

import backImage from 'assets/images/tab/backButton.svg';
import Bored from 'assets/images/mojos/bored.svg';
import Chill from 'assets/images/mojos//chill.svg';
import Anxious from 'assets/images/mojos//anxious.svg';
import Overworked from 'assets/images/mojos//overworked.svg';
import Tired from 'assets/images/mojos//tired.svg';
import Blessed from 'assets/images/mojos//blessed.svg';
import Excited from 'assets/images/mojos//excited.svg';
import Angry from 'assets/images/mojos//angry.svg';
import Productive from 'assets/images/mojos/productive.svg';
import share_icon from 'assets/images/shareicon.svg';

const MojoReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useCheckIsMobile();
  const {mojoId, idSurvey, surveyName, surveyTypeName} = useParams();
  const location = useLocation();

  const [mojoReports, setMojoReports] = useState([]);
  const [open, setOpen] = useState(false);
  const [sharePopup, setSharePopup] = useState(false);

  const mojoReportData = useSelector(state => state.report.mojoReportData);
  const SurveyReport = useSelector(state => state.report.SurveyReport);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isExportClicked, setIsExportClicked] = useState(false);

  const ExcelFileLoading = useSelector(state => state.report.surveyExcelFileLoading);
  const ExcelFileError = useSelector(state => state.report.surveyExcelFileError);
  const ExcelData = useSelector(state => state.report.surveyExcelFile);

  useEffect(() => {
    if (mojoId) {
      dispatch(ReportActions.getMojoReport(mojoId));
    } else {
      dispatch(ReportActions.getMojoReport(idSurvey));
    }
  }, [dispatch, mojoId, idSurvey]);

  useEffect(() => {
    if (mojoReportData) {
      setMojoReports(
        mojoReportData.map(item => {
          return {
            name: item.mojoName,
            percentage: item.mojoPercentage,
            image: selectImage(item.idMojo),
            id: item.idMojo,
          };
        }),
      );
    }
  }, [mojoReportData]);

  const selectImage = idMojo => {
    if (idMojo === 2) {
      return Bored;
    } else if (idMojo === 4) {
      return Tired;
    } else if (idMojo === 5) {
      return Chill;
    } else if (idMojo === 6) {
      return Anxious;
    } else if (idMojo === 7) {
      return Overworked;
    } else if (idMojo === 1) {
      return Productive;
    } else if (idMojo === 3) {
      return Angry;
    } else if (idMojo === 8) {
      return Blessed;
    } else if (idMojo === 9) {
      return Excited;
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const HandleExport = e => {
    const sendParams = {
      Type: 'Mojo',
      id: idSurvey || SurveyReport.idSurvey,
    };
    dispatch(ReportActions.getSurveyExcelFile(sendParams));
    setIsExportClicked(true);
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    if (ExcelData && isExportClicked) {
      base64ToExcelFile(
        ExcelData,
        `${surveyName || SurveyReport.surveyName} ${changeFormatOfDate(new Date().toISOString().split('T')[0])}.xlsx`,
      );
    }
  }, [ExcelData, setIsExportClicked]);

  const updateDownloadExcelFileSuccess = () => {
    setIsExportClicked(false);
  };
  const updateDownloadExcelFileFailure = () => {};

  useApiResponseHandler(
    ExcelFileLoading,
    ExcelFileError,
    updateDownloadExcelFileSuccess,
    updateDownloadExcelFileFailure,
  );

  // const DownloadPdf = () => {
  //   setIsDropdownOpen(!isDropdownOpen);
  //   const capture = document.querySelector('.actual-report');
  //   const {clientWidth, clientHeight, scrollWidth, scrollHeight} = capture;

  //   const canvasWidth = Math.max(clientWidth, scrollWidth) + 20;
  //   const canvasHeight = Math.max(clientHeight, scrollHeight) + 20;

  //   html2canvas(capture, {width: canvasWidth, height: canvasHeight}).then(canvas => {
  //     const imgData = canvas.toDataURL('.img/png');
  //     const doc = new jsPDF('landscape', 'mm', 'a2');

  //     const contentAspectRatio = canvasHeight / canvasWidth;

  //     const pdfWidth = doc.internal.pageSize.getWidth();
  //     const pdfHeight = pdfWidth * contentAspectRatio;

  //     const paddingX = 10;
  //     const paddingY = 10;

  //     doc.addImage(imgData, 'PNG', paddingX, paddingY, pdfWidth - 2 * paddingX, pdfHeight - 2 * paddingY);

  //     doc.save(`${SurveyReport.surveyName} ${changeFormatOfDate(SurveyReport?.surveyStartDate)}.pdf`);
  //   });
  // };

  const DownloadPdf = () => {
    setIsDropdownOpen(!isDropdownOpen);
    const capture = document.querySelector('.actual-report');
    const {clientWidth, clientHeight, scrollWidth, scrollHeight} = capture;

    const canvasWidth = Math.max(clientWidth, scrollWidth) + 20;
    const canvasHeight = Math.max(clientHeight, scrollHeight) + 20;

    html2canvas(capture, {width: canvasWidth, height: canvasHeight}).then(canvas => {
      const imgData = canvas.toDataURL('.img/png');
      const doc = new jsPDF('landscape', 'mm', 'a4');

      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = doc.internal.pageSize.getHeight();

      const imageAspectRatio = canvas.height / canvas.width;
      const imageWidth = pdfWidth - 40; // Adjust as needed for the padding
      const imageHeight = imageWidth * imageAspectRatio;

      const paddingX = (pdfWidth - imageWidth) / 2;
      const paddingY = (pdfHeight - imageHeight) / 2;

      doc.addImage(imgData, 'PNG', paddingX, paddingY, imageWidth, imageHeight);

      doc.save(
        `${surveyName || SurveyReport.surveyName} ${changeFormatOfDate(new Date().toISOString().split('T')[0])}.pdf`,
      );
    });
  };

  const handleShareClick = () => {
    setSharePopup(true);
  };

  const handleCloseSharePopup = () => {
    setSharePopup(false);
  };
  return (
    <>
      {idSurvey ? <AnonymousLayout /> : ''}
      <Pagelayout style={{height: 'fit-content'}}>
        <RootDiv>
          <ContentTypo variant="h2" color={colors.primary}>
            REPORT
          </ContentTypo>
          {!isMobile && (
            <HeaderExport>
              <DetailsWrapper>
                <DropdownToggle onClick={toggleDropdown} isOpen={isDropdownOpen}>
                  Export
                </DropdownToggle>

                {isDropdownOpen && (
                  <DropdownList>
                    <DropdownItem onClick={DownloadPdf}>To PDF</DropdownItem>
                    <DropdownItem onClick={HandleExport}>To Excel</DropdownItem>
                  </DropdownList>
                )}
              </DetailsWrapper>

              {location.pathname.split('/')[1] !== 'mojo-report' ? (
                <ImageDiv onClick={handleShareClick}>
                  <img style={{cursor: 'pointer'}} src={share_icon} alt="mojo" />
                </ImageDiv>
              ) : (
                ''
              )}
            </HeaderExport>
          )}
        </RootDiv>
        <div
          style={{
            display: isMobile ? 'block' : 'flex',
            gap: isMobile ? '0' : '10px',
            flexWrap: isMobile ? 'nowrap' : 'wrap',
          }}>
          <ContentTypo variant="h3" color={colors.dark}>
            {surveyName ? surveyName.replace(/\+/g, ' ') : SurveyReport?.userGroupName}
          </ContentTypo>
          <SurveyType>{surveyTypeName || SurveyReport?.surveyTypeName}</SurveyType>
        </div>
        {isMobile ? (
          <MojoDetailReport mojoReports={mojoReports} mojoReportData={mojoReportData} idMojo={idSurvey || mojoId} />
        ) : (
          <>
            <Grid container marginTop="32px">
              <Grid
                container
                sx={{display: 'flex', gap: '10px', flexFlow: 'row', alignItems: 'center'}}
                className="actual-report">
                <DetailReportStyle width="30%">
                  <MojoDetailReport mojoReports={mojoReports} mojoReportData={mojoReportData} />
                </DetailReportStyle>
                <Grid width="70%" display="flex" alignItems="center" justifyContent="center" height="500px">
                  {mojoReports.length > 0 && <MatrixMojoDetailReport mojoReports={mojoReportData} />}
                </Grid>
              </Grid>

              <Grid container marginTop="32px">
                <CommentMojo idMojo={idSurvey || mojoId} />
              </Grid>
            </Grid>
          </>
        )}

        {!isMobile && !idSurvey && (
          <BackButton>
            <NimbleButton
              color={colors.light4}
              fontWeight="700"
              label="Back"
              labelColor={colors.dark3}
              onClick={() => {
                navigate('/reports');
              }}
              size="small"
              variant="contained"
              fontFamily="Montserrat"
            />
          </BackButton>
        )}

        <div>
          {isMobile ? (
            <Dialog open={open}>
              <InfoBoxStyle isMobile={isMobile}>
                <InfoPopper open={open} onClose={() => setOpen(false)} onClickCloseHandler={() => setOpen(false)} />
              </InfoBoxStyle>
            </Dialog>
          ) : (
            <Popper open={open} style={{position: 'absolute', top: '110px', right: '75px', left: 'unset'}}>
              <InfoBoxStyle isMobile={isMobile}>
                <InfoPopper open={open} onClose={() => setOpen(false)} onClickCloseHandler={() => setOpen(false)} />
              </InfoBoxStyle>
            </Popper>
          )}
        </div>
      </Pagelayout>
      {isMobile && !idSurvey && (
        <BottomNavigation
          tabData={[
            {
              key: 'back',
              icon: backImage,
              action: () => navigate('/reports'),
            },
          ]}
        />
      )}
      {sharePopup && (
        <ShareModal
          open={sharePopup}
          noButtonHanlder={handleCloseSharePopup}
          onClose={handleCloseSharePopup}
          yesButtonHanlder={() => {}}
        />
      )}
    </>
  );
};

export default MojoReport;

const RootDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const ContentTypo = styled(Typography)(({color}) => ({
  color: color,
  textTransform: 'uppercase',
}));

const BackButton = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  padding: '20px 0px',
  cursor: 'pointer',
});

const InfoBoxStyle = styled(Box)(({isMobile}) => ({
  padding: '16px 24px',
  width: !isMobile && '347px',
}));

const DetailReportStyle = styled(Grid)({
  borderRadius: '15px',
  background: colors.light9,
  padding: '16px',
  height: '100%',
});

const DetailsWrapper = styled('div')({
  position: 'relative',
});

const DropdownToggle = styled('div')(({isOpen}) => ({
  borderRadius: '5px',
  fontFamily: 'Montserrat',
  fontSize: '15px',
  fontWeight: 700,
  listStyle: 'none',
  display: 'inline-flex',
  gap: '8px',
  padding: '8px 16px',
  backgroundColor: '#E0E3EB',
  cursor: 'pointer',
  position: 'relative',
  justifyContent: 'center',
  width: '120px',
  '&::after': {
    borderStyle: 'solid',
    borderWidth: '0.15em 0.15em 0 0',
    content: '""',
    display: 'inline-block',
    height: '0.5em',
    left: 0,
    position: 'relative',
    verticalAlign: 'top',
    width: '0.5em',
    top: isOpen ? '6px' : '5px',
    transform: isOpen ? 'rotate(314deg)' : 'rotate(136deg)',
    transition: 'transform 0.3s ease',
  },
}));

const DropdownList = styled('ul')({
  position: 'absolute',
  top: '100%',
  left: 0,
  listStyle: 'none',
  margin: 0,
  padding: 0,
  backgroundColor: '#E0E3EB',
  width: '120px',
  borderRadius: '5px',
});

const DropdownItem = styled('li')({
  padding: '8px 26px',
  cursor: 'pointer',
  width: '100%',
  fontFamily: 'Montserrat',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 500,
  borderRadius: '5px',
  '&:hover': {
    background: '#ccc',
  },
});

const SurveyType = styled('div')({
  backgroundColor: '#E0E3EB',
  color: '#9A9FA5',
  fontSize: '14px !important',
  lineHeight: '21px !important',
  fontFamily: 'Montserrat !important',
  fontWeight: '400 !important',
  width: 'fit-content',
  height: 'fit-content',
  padding: '2px 4px 2px 4px',
  borderRadius: '3px',
});

const ImageDiv = styled('div')({
  backgroundColor: colors.light4,
  padding: '8px 8px 0px 8px',
  borderRadius: '5px',
});
const HeaderExport = styled('div')({
  display: 'flex',
  gap: '10px',
});
