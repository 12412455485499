import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Dialog, Typography} from '@mui/material';

import {isValidEmail} from 'app/utils/validPassword';
import {actions as ReportAction} from 'app/store/slices/report.slice';

import {NimbleButton, NimbleInput} from 'nimble-design-system';
import IconButton from '../../../../assets/images/CancelIcon.svg';
import {colors} from 'theme';
import {commonStyleProps} from 'app/shared/constant';
import {styled} from '@mui/system';

const ShareModal = ({onClose, open, noButtonHanlder, yesButtonHanlder}) => {
  const SurveyReport = useSelector(state => state.report.SurveyReport);
  const [formError, setFormError] = useState(null);
  const dispatch = useDispatch();
  const [credential, setCredentail] = useState({
    email: '',
    password: '',
  });

  const validateEmails = emails => {
    const emailArray = emails.split(',').map(email => email.trim());
    const invalidEmails = emailArray.filter(email => !isValidEmail(email));
    return invalidEmails.length === 0;
  };

  const emailChangeHandler = value => {
    if (value) {
      if (validateEmails(value)) {
        setCredentail(prevCredential => ({...prevCredential, email: value}));
        setFormError({
          email: null,
        });
      } else {
        setFormError({
          email: 'Please enter valid email',
        });
      }
    } else {
      setFormError({
        email: 'Email required',
      });
    }
  };

  const passwordChangeHandler = value => {
    if (value) {
      setCredentail(prevCredential => ({...prevCredential, password: value}));
    }
  };

  const SendData = () => {
    if (credential.email && !formError?.email) {
      dispatch(
        ReportAction.shareReport({
          idSurvey: SurveyReport.idSurveyType === 3 ? SurveyReport.idSurveyInstance : SurveyReport.idSurvey,
          email: [credential.email.toLowerCase()],
          password: credential.password,
          surveyType: SurveyReport.idSurveyType,
        }),
      );
      noButtonHanlder();
    } else {
      setFormError({
        email: credential.email === '' ? 'Email required' : null,
      });
    }
  };
  return (
    <div>
      <Dailogroot onClose={onClose} open={open}>
        <Header>
          <HeaderName> {`SHARE ${SurveyReport.surveyName} REPORT`}</HeaderName>
          <img src={IconButton} onClick={noButtonHanlder} alt="share_icon" />
        </Header>

        <Main>
          <NimbleInput
            {...commonStyleProps}
            label="Share with"
            onChange={value => {
              emailChangeHandler(value);
            }}
            placeholder="Enter email"
            type="text"
            isError={formError?.email}
            errorMessage={formError?.email}
          />
          <NimbleInput
            {...commonStyleProps}
            label="Set password"
            onChange={value => {
              passwordChangeHandler(value);
            }}
            placeholder="Enter password"
          />
        </Main>

        <Footer>
          <NimbleButton
            color={colors.primary2}
            fontWeight="700"
            label="Send"
            onClick={SendData}
            size="large"
            variant="contained"
            fontFamily="Montserrat"
            fontSize="16px"
          />
        </Footer>
      </Dailogroot>
    </div>
  );
};

export default ShareModal;

const Dailogroot = styled(Dialog)({
  '& .MuiPaper-root': {
    padding: '16px',
  },
});

const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const Main = styled('div')({
  marginTop: '20px',
  width: '500px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

const HeaderName = styled(Typography)({
  fontFamily: 'Montserrat',
  fontSize: '24px',
  fontWeight: '700',
  lineHeight: '36px',
  letterSpacing: '0em',
  textAlign: 'left',
  color: colors.primary,
});

const Footer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
  marginTop: '20px',
});
