import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  overallReportData: [],
  overallReportDataLoading: false,
  overallReportDataError: null,

  detailedReport: [],
  detailedReportLoading: false,
  detailedReportError: null,

  surveyReport: [],
  surveyReportLoading: false,
  surveyReportError: null,

  mojoReportData: [],
  mojoReportLoading: false,
  mojoReportError: null,

  mojoCommentsData: [],
  mojoCommentsLoading: false,
  mojoCommentsError: null,

  IdSurveyInstance: null,

  surveyExcelFileLoading: false,
  surveyExcelFile: null,
  surveyExcelFileError: null,

  shareReportLoading: false,
  shareReportError: null,

  shareReportPasswordLoading: false,
  shareReportPasswordError: null,
};

const getOverallReport = state => {
  state.overallReportDataLoading = true;
  state.overallReportDataError = null;
};

const getOverallReportSuccess = (state, action) => {
  const data = action.payload;

  const temp = {
    current: `${data.currentStage | 0}%`,
    start: `${data.startStage | 0}%`,
    target: `${data.targetStage | 0}%`,
  };
  state.overallReportData = temp;
  state.overallReportDataLoading = false;
  state.overallReportDataError = null;
};

const getOverallReportFailure = (state, action) => {
  state.overallReportDataLoading = false;
  state.overallReportDataError = action.payload;
};

const getDetailedReport = state => {
  state.detailedReportLoading = true;
  state.detailedReportError = null;
};

const getDetailedReportSuccess = (state, action) => {
  const data = action.payload
    ? action.payload.map(item => ({
        current: `${item.currentStage}%`,
        name: item.topicName,
        start: `${item.startStage}%`,
        target: item.targetStage ? `${item.targetStage}%` : '0%',
        TopicId: item?.idTopic,
      }))
    : [];
  state.detailedReport = data;
  state.detailedReportLoading = false;
  state.detailedReportError = null;
};

const getDetailedReportFailure = (state, action) => {
  state.detailedReportLoading = false;
  state.detailedReportError = action.payload;
};

const getSurveyReport = (state, action) => {
  state.surveyReportLoading = true;
  state.surveyReportError = null;
};

const getSurveyReportSuccess = (state, action) => {
  state.surveyReport = action.payload;
  state.surveyReportLoading = false;
  state.surveyReportError = null;
};

const getSurveyReportFailure = (state, action) => {
  state.surveyReportLoading = false;
  state.surveyReportError = action.payload;
};

const getMojoReport = (state, action) => {
  state.mojoReportLoading = true;
  state.mojoReportError = null;
};

const getMojoReportSuccess = (state, action) => {
  state.mojoReportData = action.payload;
  state.mojoReportLoading = false;
  state.mojoReportError = null;
};

const getMojoReportFailure = (state, action) => {
  state.mojoReportLoading = false;
  state.mojoReportError = action.payload;
};

const getMojoComments = (state, action) => {
  state.mojoCommentsLoading = true;
  state.mojoCommentsError = null;
};

const getMojoCommentsSuccess = (state, action) => {
  state.mojoCommentCount = action.payload.count;
  state.mojoCommentsData = action.payload.responseModels;
  state.mojoCommentsLoading = false;
  state.mojoCommentsError = null;
};

const getMojoCommentsFailure = (state, action) => {
  state.mojoCommentsLoading = false;
  state.mojoCommentsError = action.payload;
};

const setIdSurveyInstance = (state, action) => {
  state.IdSurveyInstance = action.payload;
};

const setSurveyReport = (state, action) => {
  const temp = action.payload;
  state.SurveyReport = temp;
};

const getSurveyExcelFile = (state, action) => {
  state.surveyExcelFileLoading = true;
};
const getSurveyExcelFileSuccess = (state, action) => {
  state.surveyExcelFile = action.payload;
  state.surveyExcelFileLoading = false;
};
const getSurveyExcelFileFailure = (state, action) => {
  state.surveyExcelFileLoading = false;
  state.surveyExcelFileError = action.payload;
};

const shareReport = (state, action) => {
  state.shareReportLoading = true;
  state.shareReportError = null;
};
const shareReportSuccess = (state, action) => {
  state.shareReportLoading = false;
  state.shareReportError = null;
};
const shareReportFailure = (state, action) => {
  state.shareReportLoading = false;
  state.shareReportError = action.payload;
};

const reportPasswordValidation = (state, action) => {
  state.shareReportPasswordLoading = true;
  state.shareReportPasswordError = null;
};
const reportPasswordValidationSuccess = (state, action) => {
  state.shareReportPasswordLoading = false;
  state.shareReportPasswordError = null;
};
const reportPasswordValidationFailure = (state, action) => {
  state.shareReportPasswordLoading = false;
  state.shareReportPasswordError = action.payload;
};

const reportSlice = createSlice({
  name: '@@report',
  initialState,
  reducers: {
    getOverallReport(state, action) {
      return getOverallReport(state, action);
    },
    getOverallReportSuccess(state, action) {
      return getOverallReportSuccess(state, action);
    },
    getOverallReportFailure(state, action) {
      return getOverallReportFailure(state, action);
    },
    getDetailedReport(state, action) {
      return getDetailedReport(state, action);
    },
    getDetailedReportSuccess(state, action) {
      return getDetailedReportSuccess(state, action);
    },
    getDetailedReportFailure(state, action) {
      return getDetailedReportFailure(state, action);
    },
    getSurveyReport(state, action) {
      return getSurveyReport(state, action);
    },
    getSurveyReportSuccess(state, action) {
      return getSurveyReportSuccess(state, action);
    },
    getSurveyReportFailure(state, action) {
      return getSurveyReportFailure(state, action);
    },
    getMojoReport(state, action) {
      return getMojoReport(state, action);
    },
    getMojoReportSuccess(state, action) {
      return getMojoReportSuccess(state, action);
    },
    getMojoReportFailure(state, action) {
      return getMojoReportFailure(state, action);
    },
    getMojoComments(state, action) {
      return getMojoComments(state, action);
    },
    getMojoCommentsSuccess(state, action) {
      return getMojoCommentsSuccess(state, action);
    },
    getMojoCommentsFailure(state, action) {
      return getMojoCommentsFailure(state, action);
    },
    setIdSurveyInstance(state, action) {
      return setIdSurveyInstance(state, action);
    },
    setSurveyReport(state, action) {
      return setSurveyReport(state, action);
    },

    getSurveyExcelFile(state, action) {
      return getSurveyExcelFile(state, action);
    },
    getSurveyExcelFileSuccess(state, action) {
      return getSurveyExcelFileSuccess(state, action);
    },

    getSurveyExcelFileFailure(state, action) {
      return getSurveyExcelFileFailure(state, action);
    },

    shareReport(state, action) {
      return shareReport(state, action);
    },
    shareReportSuccess(state, action) {
      return shareReportSuccess(state, action);
    },
    shareReportFailure(state, action) {
      return shareReportFailure(state, action);
    },

    reportPasswordValidation(state, action) {
      return reportPasswordValidation(state, action);
    },
    reportPasswordValidationSuccess(state, action) {
      return reportPasswordValidationSuccess(state, action);
    },
    reportPasswordValidationFailure(state, action) {
      return reportPasswordValidationFailure(state, action);
    },
  },
});

export const {name, actions, reducer} = reportSlice;
