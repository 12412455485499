import {createSlice} from '@reduxjs/toolkit';
import {setWithExpiry} from 'app/utils/LocalStorage';

const initialState = {
  userLoginData: null,
  userLoginLoading: false,
  userLoginError: null,

  getTokenData: null,
  getTokenLoading: false,
  getTokenError: null,

  userInfo: null,
  userInfoLoading: false,
  userInfoError: null,

  updateTncLoading: false,
  updateTncError: null,

  postUserLoginData: null,
  postUserLoginLoading: false,
  postUserLoginError: null,

  postAnonymousValidationData: null,
  postAnonymousValidationLoading: false,
  postAnonymousValidationError: null,

  anonymousIdsurvey: null,
  anonymousIdsurveyName: '',

  postWorkspaceValidationData: null,
  postWorkspaceValidationLoading: false,
  postWorkspaceValidationError: null,

  postInvitationAddUserLoading: false,
  postInvitationAddUserData: null,
  postInvitationAddUserError: null,

  invitationGuiId: null,
};

const getToken = state => {
  state.getTokenLoading = true;
  state.getTokenError = null;
};

const getTokenSuccess = (state, action) => {
  const data = action.payload;
  if (data.access_token) {
    setWithExpiry('token', data.access_token, data.expires_in * 1000);
  }

  state.getTokenData = data;
  state.getTokenLoading = false;
  state.getTokenError = null;
};

const getTokenFailure = (state, action) => {
  state.getTokenLoading = false;
  state.getTokenData = null;
  state.getTokenError = action.payload;
};

const logout = (state, action) => {
  state.userLoginData = null;
  state.userLoginError = null;
  state.userInfo = null;
};

const getUserInfo = state => {
  state.userInfoLoading = true;
  state.userInfoError = null;
};

const getUserInfoSuccess = (state, action) => {
  const {response} = action.payload;
  let userData = {};
  if (response === 'User not registered') {
    userData['isNotRegistered'] = true;
  } else {
    userData = response[0];
  }

  state.userInfo = userData;
  state.userInfoLoading = false;
  state.userInfoError = null;
};

const getUserInfoFailure = (state, action) => {
  state.userInfoError = action.payload;
  state.userInfo = null;
  state.userInfoLoading = false;
};

const updateTnC = (state, action) => {
  state.updateTncLoading = true;
  state.updateTncError = null;
};

const updateTnCSuccess = (state, action) => {
  state.updateTncLoading = false;
  state.updateTncError = null;
};

const updateTnCFailure = (state, action) => {
  state.updateTncLoading = false;
  state.updateTncError = action.payload;
};

const postUserLoginData = (state, action) => {
  state.postUserLoginLoading = true;
};
const postUserLoginDataSuccess = (state, action) => {
  const data = action.payload;
  if (data.token) {
    setWithExpiry('token', data.token, data.expiresIn * 1000);
  }

  state.postUserLoginData = action.payload;
  state.postUserLoginLoading = false;
  state.postUserLoginError = null;
};
const postUserLoginDataFailure = (state, action) => {
  state.postUserLoginLoading = false;
  state.postUserLoginError = action.payload;
};

const postAnonymousValidationData = (state, action) => {
  state.postAnonymousValidationLoading = true;
};
const postAnonymousValidationDataSuccess = (state, action) => {
  state.postAnonymousValidationLoading = false;
  state.postAnonymousValidationData = action.payload;
};
const postAnonymousValidationDataFailure = (state, action) => {
  state.postAnonymousValidationLoading = false;
  state.postAnonymousValidationError = action.payload;
};

const getSurveyInstanceForAnonymous = (state, action) => {
  state.anonymousIdsurvey = action.payload;
};

const getSurveyNameForAnonymous = (state, action) => {
  state.anonymousIdsurveyName = action.payload;
};

const postWorkspaceValidationData = (state, action) => {
  state.postWorkspaceValidationLoading = true;
};
const postWorkspaceValidationDataSuccess = (state, action) => {
  state.postWorkspaceValidationLoading = false;
  state.postWorkspaceValidationData = action.payload;
};
const postWorkspaceValidationDataFailure = (state, action) => {
  state.postWorkspaceValidationLoading = false;
  state.postWorkspaceValidationError = action.payload;
};

const postInvitationAddUser = (state, action) => {
  state.postInvitationAddUserLoading = true;
};
const postInvitationAddUserSuccess = (state, action) => {
  state.postInvitationAddUserLoading = false;
  state.postInvitationAddUserData = action.payload;
};
const postInvitationAddUserError = (state, action) => {
  state.postInvitationAddUserLoading = false;
  state.postInvitationAddUserError = action.payload;
};

const setGuiId = (state, action) => {
  state.invitationGuiId = action.payload;
};

const authenticationSlice = createSlice({
  name: '@@authentication',
  initialState,
  reducers: {
    getToken(state, action) {
      return getToken(state, action);
    },
    getTokenSuccess(state, action) {
      return getTokenSuccess(state, action);
    },
    getTokenFailure(state, action) {
      return getTokenFailure(state, action);
    },
    logout(state, action) {
      return logout(state, action);
    },
    getUserInfo(state, action) {
      return getUserInfo(state, action);
    },
    getUserInfoSuccess(state, action) {
      return getUserInfoSuccess(state, action);
    },
    getUserInfoFailure(state, action) {
      return getUserInfoFailure(state, action);
    },
    updateTnC(state, action) {
      return updateTnC(state, action);
    },
    updateTnCSuccess(state, action) {
      return updateTnCSuccess(state, action);
    },
    updateTnCFailure(state, action) {
      return updateTnCFailure(state, action);
    },
    handleUserLogin(state, action) {
      return postUserLoginData(state, action);
    },
    handleUserLoginSuccess(state, action) {
      return postUserLoginDataSuccess(state, action);
    },
    handleUserLoginFailure(state, action) {
      return postUserLoginDataFailure(state, action);
    },
    handleAnonymousValidation(state, action) {
      return postAnonymousValidationData(state, action);
    },
    handleAnonymousValidationSuccess(state, action) {
      return postAnonymousValidationDataSuccess(state, action);
    },
    handleAnonymousValidationFailure(state, action) {
      return postAnonymousValidationDataFailure(state, action);
    },
    getSurveyInstanceForAnonymous(state, action) {
      return getSurveyInstanceForAnonymous(state, action);
    },
    getSurveyNameForAnonymous(state, action) {
      return getSurveyNameForAnonymous(state, action);
    },

    handleWorkspaceValidation(state, action) {
      return postWorkspaceValidationData(state, action);
    },
    handleWorkspaceValidationSuccess(state, action) {
      return postWorkspaceValidationDataSuccess(state, action);
    },
    handleWorkspaceValidationFailure(state, action) {
      return postWorkspaceValidationDataFailure(state, action);
    },

    postInvitationAddUser(state, action) {
      return postInvitationAddUser(state, action);
    },
    postInvitationAddUserSuccess(state, action) {
      return postInvitationAddUserSuccess(state, action);
    },
    postInvitationAddUserError(state, action) {
      return postInvitationAddUserError(state, action);
    },

    setGuiId(state, action) {
      return setGuiId(state, action);
    },
  },
});

export const {name, actions, reducer} = authenticationSlice;
